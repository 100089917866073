import React from "react";
import { Button, Flex, Layout, theme } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.config";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../assets/logo.png'
const { Header, Content, Footer } = Layout;
const PageLayout = ({ children }) => {
  const navigate = useNavigate();
  let location = useLocation();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 0.5rem 0 0",
        }}
      >
        <img src={Logo} alt="" srcSet="" style={{ height: '3.25rem'}}/>
        {location?.pathname !== "/" && (
          <Button
            icon={<LogoutOutlined />}
            onClick={async () => {
              await signOut(auth);
              navigate("/");
            }}
          />
        )}
      </Header>
      <Content
        style={{
          height: "100%",
          flexGrow: 1,
          backgroundColor: '#fff'
        }}
      >
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: "4px",
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Chip Count ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};
export default PageLayout;
