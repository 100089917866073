import React from "react";

function PokerTransactions({ settlementData }) {
  const minimizeTransactions = (settlementData) => {
    let creditors = {};
    let debtors = {};
    let transactions = [];

    settlementData.forEach((participant) => {
      if (participant.payOutAmount > 0) {
        creditors[participant.name] = participant.payOutAmount;
      } else if (participant.payOutAmount < 0) {
        debtors[participant.name] = -participant.payOutAmount; // Make it positive
      }
    });

    while (
      Object.keys(creditors).length > 0 &&
      Object.keys(debtors).length > 0
    ) {
      const debtorName = Object.keys(debtors).reduce((a, b) =>
        debtors[a] < debtors[b] ? a : b
      );
      const creditorName = Object.keys(creditors).reduce((a, b) =>
        creditors[a] < creditors[b] ? a : b
      );

      const amount = Math.min(debtors[debtorName], creditors[creditorName]);
      transactions.push({ debtor: debtorName, creditor: creditorName, amount });

      debtors[debtorName] -= amount;
      creditors[creditorName] -= amount;

      if (debtors[debtorName] === 0) {
        delete debtors[debtorName];
      }
      if (creditors[creditorName] === 0) {
        delete creditors[creditorName];
      }
    }

    return transactions.sort((a, b) => b.amount - a.amount);
  };

  const transactions = minimizeTransactions(settlementData);

  return (
    <div>
      <h4>Game Settlement</h4>
      <ul>
        {transactions.map((transaction, index) => (
          <li key={index}>
            {transaction.debtor} owes {transaction.creditor} ₹
            {transaction.amount}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PokerTransactions;
