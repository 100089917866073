import { Table, Typography, Spin, Alert, Flex, Button, Input } from "antd"; // Import Spin and Alert for loading and error states
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase.config";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Search } = Input;

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
const formatNumber = (value) => {
  return new Intl.NumberFormat('en-IN').format(value);
};

const Leaderboard = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Winnings",
      dataIndex: "winnings",
      key: "winnings",
      render: (text) => formatNumber(text),
    },
    {
      title: "Games Played",
      dataIndex: "gamesPlayed",
      key: "gamesPlayed",
    },
    // {
    //   title: "Buy Ins",
    //   dataIndex: "buyIns",
    //   key: "buyIns",
    // },
  ];

  const [leaderboardData, setLeaderboardData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "players"),
          orderBy("winnings", "desc"),
          orderBy("gamesPlayed", "asc"),
          // orderBy("buyIns", "asc"),
          orderBy("name", "asc")
        );
        const querySnapshot = await getDocs(q);

        // Map over the document snapshots and get data
        const playersList = querySnapshot.docs.map((doc, index) => ({
          key: doc.id,
          id: doc.id,
          rank: index + 1,
          ...doc.data(),
          name: toTitleCase(doc.data()?.name),
        }));
        setLeaderboardData(playersList);
        setFilteredData(playersList); // Initialize filteredData with the original data
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        setError(
          "An error occurred while fetching data. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value) => {
    const filtered = leaderboardData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <div>
      <Title level={3}> Leaderboard </Title>
      {loading ? (
        <Flex justify="center">
          <Spin size="large" />
        </Flex>
      ) : error ? (
        <Alert message={error} type="error" />
      ) : (
        <>
          <Search
            placeholder="Search player name"
            allowClear
            onSearch={handleSearch}
            style={{ width: "100%", marginBottom: 16, borderRadius: 0 }}
          />
          <Table
            dataSource={filteredData}
            columns={columns}
            rowKey={(record) => record.id}
          />
          <Flex justify="center">
            <Button type="primary" onClick={() => navigate("/create-game")}>
              Start New Game
            </Button>
          </Flex>
        </>
      )}
    </div>
  );
};

export default Leaderboard;
