import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Table,
  Flex,
  Row,
  Col,
  Typography,
  Tooltip,
  message,
} from "antd";
import { useParams } from "react-router-dom";
import { db } from "../../firebase.config";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  DeleteOutlined,
  PlusOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  DollarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AddBuyIn from "./AddBuyin";
import ChipOut from "./ChipOut";
import SettlementData from "./SettlmentData";
import EditModal from "./EditModal";

const { Option } = Select;
const { Title } = Typography;

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const GameDetails = () => {
  const [form] = Form.useForm();

  const { gameId } = useParams(); // Retrieve gameId from route parameters
  const [gameData, setGameData] = useState(null);
  const [addPlayerVisible, setAddPlayerVisible] = useState(false);
  const [addBuyInVisible, setAddBuyInVisible] = useState(false);
  const [chipOutVisible, setChipOutVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [players, setPlayers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [showSettlementScreen, setShowSettlementScreen] = useState(false);
  const [gameDataLoading, setGameDataLoading] = useState(true);
  const [settlementData, setSettlementData] = useState(null);
  useEffect(() => {
    fetchGameData();
    fetchPlayers();
  }, []);

  const fetchGameData = async () => {
    try {
      const docRef = doc(db, "games", gameId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const gameData = docSnap.data();
        setGameData(gameData);
        setShowSettlementScreen(gameData?.status === "ended");
        if (gameData?.status === "ended") {
          const { evaluatedData } = gameData?.players?.reduce(
            (acc, curr) => {
              return {
                evaluatedData: [
                  ...acc?.evaluatedData,
                  {
                    name: curr?.name,
                    payOutAmount: curr?.chipOutAmount - curr?.buyInAmount,
                    buyInAmount: curr?.buyInAmount,
                    playerId: curr?.playerId,
                  },
                ],
              };
            },
            { evaluatedData: [] }
          );
          setSettlementData(evaluatedData);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching game data:", error);
    } finally {
      setGameDataLoading(false);
    }
  };

  const fetchPlayers = async () => {
    try {
      const response = await getDocs(collection(db, "players"));
      const playersData = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc?.id,
        name: toTitleCase(doc?.data()?.name),
      }));
      setPlayers(playersData.sort((a, b) => (a.name > b.name ? 1 : -1)));
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const handleAddPlayer = async (values) => {
    const validationMessage = getPlayerListValidationMessage(values);
    if (validationMessage) {
      setErrorMessage(validationMessage);
      return;
    }
    const newPlayers = values?.players?.map((player) => ({
      ...player,
      settled: false,
      buyInAmount: Number(player?.buyInAmount),
    }));
    const docRef = doc(db, "games", gameId);
    await updateDoc(docRef, {
      players: [...gameData.players, ...newPlayers],
    });
    setGameData((prev) => ({
      ...prev,
      players: [...prev.players, ...newPlayers],
    }));
    form.resetFields();
    setAddPlayerVisible(false);
  };
  const formatNumber = (value) => {
    return new Intl.NumberFormat("en-IN").format(value);
  };
  const columns = [
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Buy-ins",
      dataIndex: "buyInAmount",
      key: "buyInAmount",
      render: (text) => text ? formatNumber(text) : text,
    },
    {
      title: "ChipOut",
      dataIndex: "chipOutAmount",
      key: "chipOutAmount",
      render: (text) => text ? formatNumber(text) : text,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Flex gap={2}>
          <Button
            onClick={() => {
              setSelectedPlayer(record);
              setAddBuyInVisible(true);
            }}
            icon={<PlusCircleOutlined />}
            disabled={record?.chipOutAmount || record?.chipOutAmount >= 0}
          />
          <Button
            onClick={() => {
              setSelectedPlayer(record);
              setChipOutVisible(true);
            }}
            icon={<DollarOutlined />}
            disabled={record?.chipOutAmount || record?.chipOutAmount >= 0}
          />
          <Button
            onClick={() => {
              setSelectedPlayer(record);
              setEditModalVisible(true);
            }}
            icon={<EditOutlined />}
          />
        </Flex>
      ),
    },
  ];

  const handleAddBuyIn = async (selectedPlayer, topUpAmount, form) => {
    const updatedPlayerData = gameData?.players.map((player) => {
      if (player?.playerId === selectedPlayer?.playerId) {
        return { ...player, buyInAmount: player?.buyInAmount + topUpAmount };
      }
      return player;
    });
    const docRef = doc(db, "games", gameId);
    await updateDoc(docRef, {
      players: updatedPlayerData,
    });
    setGameData((prev) => ({
      ...prev,
      players: updatedPlayerData,
    }));
    setAddBuyInVisible(false);
    form.resetFields();
  };

  const handleEditData = async (
    selectedPlayer,
    buyInAmount,
    chipOutAmount,
    form
  ) => {
    const updatedPlayerData = gameData?.players.map((player) => {
      if (player?.playerId === selectedPlayer?.playerId) {
        return {
          ...player,
          buyInAmount: buyInAmount,
          chipOutAmount: chipOutAmount,
        };
      }
      return player;
    });
    const docRef = doc(db, "games", gameId);
    await updateDoc(docRef, {
      players: updatedPlayerData,
    });
    setGameData((prev) => ({
      ...prev,
      players: updatedPlayerData,
    }));
    setEditModalVisible(false);
    // form.resetFields();
  };

  const handleChipOut = async (selectedPlayer, chipOutAmount, form) => {
    const updatedPlayerData = gameData?.players.map((player) => {
      if (player?.playerId === selectedPlayer?.playerId) {
        return { ...player, chipOutAmount };
      }
      return player;
    });
    const docRef = doc(db, "games", gameId);
    await updateDoc(docRef, {
      players: updatedPlayerData,
    });
    setGameData((prev) => ({
      ...prev,
      players: updatedPlayerData,
    }));
    setChipOutVisible(false);
    form.resetFields();
  };

  const handleEndGame = async () => {
    const validationMessage = getSettleGameValidationMessage();
    if (validationMessage) {
      setErrorMessage(validationMessage);
      return;
    }
    setErrorMessage("");
    const { evaluatedData, docsToUpdate } = gameData?.players?.reduce(
      (acc, curr) => {
        return {
          evaluatedData: [
            ...acc?.evaluatedData,
            {
              name: curr?.name,
              payOutAmount: curr?.chipOutAmount - curr?.buyInAmount,
              buyInAmount: curr?.buyInAmount,
              playerId: curr?.playerId,
            },
          ],
          docsToUpdate: [
            ...acc.docsToUpdate,
            {
              id: curr?.playerId,
              newData: {
                winnings:
                  gameData?.status === "ended"
                    ? increment(0)
                    : increment(curr?.chipOutAmount - curr?.buyInAmount),
                buyIns:
                  gameData?.status === "ended"
                    ? increment(0)
                    : increment(curr?.buyInAmount),
                gamesPlayed:
                  gameData?.status === "ended" ? increment(0) : increment(1),
              },
            },
          ],
        };
      },
      { evaluatedData: [], docsToUpdate: [] }
    );
    if (gameData?.status === "inProgress") {
      const batch = writeBatch(db); // Create a new batch

      const gameStatsRef = doc(db, "games", gameId);
      batch.update(gameStatsRef, {
        endedAt: new Date(),
        status: "ended",
      });

      docsToUpdate.forEach((docInfo) => {
        const docRef = doc(db, "players", docInfo.id);
        batch.set(docRef, docInfo.newData, { merge: true });
      });

      try {
        await batch.commit();
        setSettlementData(evaluatedData);
        setShowSettlementScreen(true);
        // console.log("Both collections updated successfully");
      } catch (error) {
        console.error("Failed to update collections: ", error);
      }
    }
    if (gameData?.status === "ended") {
      setSettlementData(evaluatedData);
      setShowSettlementScreen(true);
    }
  };
  // const handleEndGame = async () => {
  //   const validationMessage = getSettleGameValidationMessage();
  //   if (validationMessage) {
  //     setErrorMessage(validationMessage);
  //     return;
  //   }
  //   const evaluatedData = gameData?.players?.map((player) => ({
  //     name: player?.name,
  //     payOutAmount: player?.chipOutAmount - player?.buyInAmount,
  //   }));
  //   const docRef = doc(db, "games", gameId);
  //   await updateDoc(docRef, {
  //     endedAt: new Date(),
  //     status: "ended",
  //   });
  //   setSettlementData(evaluatedData);
  //   setShowSettlementScreen(true);
  // };

  const handlePlayerSelect = async (value, option) => {
    setErrorMessage("");
    const playerExists = players.find((player) => player.name === value);

    const fieldKey = option.key;
    const newPlayers = [...form.getFieldValue("players")];
    newPlayers[fieldKey] = {
      ...newPlayers[fieldKey],
      playerId: playerExists.id,
    };
    setValue(value);
    form.setFieldsValue({ players: newPlayers });
  };

  const getPlayerListValidationMessage = ({ players }) => {
    const names = players?.map((obj) => obj.name.toLowerCase());
    const uniqueNames = new Set(names);
    if (names.length !== uniqueNames.size) return "Duplicate player names";
    let validationMessage = "";
    gameData?.players?.forEach((player) => {
      if (names.includes(player?.name))
        validationMessage = "Player already in the game";
    });
    return validationMessage;
  };
  const initialValues = {
    players: [
      { name: "", buyInAmount: gameData?.minBuyIn }, // Initial set with default buy-in amount
    ],
  };

  const getSettleGameValidationMessage = () => {
    const { chipOutCount, buyInSum, chipOutSum } = gameData?.players?.reduce(
      (acc, curr) => {
        if (!(curr?.chipOutAmount || curr?.chipOutAmount >= 0)) {
          acc = { ...acc, chipOutCount: acc?.chipOutCount + 1 };
        }
        acc = {
          ...acc,
          buyInSum: acc.buyInSum + curr?.buyInAmount,
          chipOutSum: acc.chipOutSum + curr?.chipOutAmount,
        };
        return acc;
      },
      { chipOutCount: 0, buyInSum: 0, chipOutSum: 0 }
    );
    if (chipOutCount > 0) return "Chip-out for 1 or more players pending";
    if (buyInSum - chipOutSum !== 0)
      return `Difference in buy-in and chip-out is ${buyInSum - chipOutSum}`;
    return "";
  };

  const onAddBuyInClose = () => {
    setAddBuyInVisible(false);
  };

  const onChipOutClose = () => {
    setChipOutVisible(false);
  };

  const onEditModalClose = () => {
    setEditModalVisible(false);
  };

  const getFormattedDate = (date) => {
    if (date) {
      const pad = (num) => (num < 10 ? "0" + num : num); // Pad single digits with leading zero

      let day = pad(date.getDate());
      let month = pad(date.getMonth() + 1); // getMonth() returns month from 0-11
      let year = date.getFullYear();
      let hours = date.getHours();
      let minutes = pad(date.getMinutes());
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? pad(hours) : 12; // Convert hour '0' to '12'

      return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    }
  };
  const [value, setValue] = useState(undefined);
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text) => {
    setSearchText(text);
  };
  const handleAddNewPlayer = async (name) => {
    try {
      if (
        !players?.find(
          (player) => player?.name?.toLowerCase() === name?.toLowerCase()
        )
      ) {
        const { id } = await addDoc(collection(db, "players"), {
          name: name.toLowerCase(),
          createdAt: new Date(),
          gamesPlayed: 0,
          winnings: 0,
          email: "",
        });
        const newPlayer = { id, name: toTitleCase(name) };
        setPlayers((prev) => [...prev, newPlayer]);
        setValue(name);
        message.success("Player added successfully!");
      } else {
        message.warning("Player already exists!");
      }
    } catch (error) {
      message.error("Failed to add player");
      console.error("Error adding document: ", error);
    }
  };

  return gameDataLoading ? (
    <>Loading...</>
  ) : !showSettlementScreen ? (
    <>
      <Title level={3}>Game details</Title>
      <Flex vertical gap={8} style={{ color: "#373737" }}>
        <div>
          <b>Game started at:</b>{" "}
          {getFormattedDate(gameData?.startedAt.toDate()) || "-"}
        </div>
        <Flex gap={10}>
          <div>
            <b>Total players:</b> {gameData?.players?.length || "-"}
          </div>
          <div>
            <b>Total buy-ins:</b>{" "}
            {gameData?.players.reduce(
              (acc, curr) => (acc += curr?.buyInAmount),
              0
            ) || "-"}
          </div>
          <div>
            <b>Average buy-in:</b>{" "}
            {gameData?.players?.length > 0
              ? Math.ceil(
                  gameData?.players.reduce(
                    (acc, curr) => (acc += curr?.buyInAmount),
                    0
                  ) / gameData?.players?.length
                )
              : "-"}
          </div>
        </Flex>
      </Flex>

      <Flex gap={10} style={{ margin: "16px 0" }} justify="end">
        <Button
          onClick={() => {
            setAddPlayerVisible(true);
            setErrorMessage("");
          }}
        >
          Add Player
        </Button>
        <Button onClick={handleEndGame}>End Game</Button>
      </Flex>
      <p style={{ color: "red", textAlign: "center" }}>
        <small>{errorMessage}</small>
      </p>
      <Modal
        centered
        title="Add New Players"
        open={addPlayerVisible}
        onCancel={() => {
          setAddPlayerVisible(false);
          form.resetFields();
          setErrorMessage("");
        }}
        footer={null}
      >
        <Row
          gutter={{
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          }}
        >
          <Col className="gutter-row" span={11}>
            <Title level={5}>Player Name</Title>
          </Col>
          <Col className="gutter-row" span={10}>
            <Title level={5}> Buy-in Amount </Title>
          </Col>
        </Row>
        <Form
          form={form}
          name="dynamic_form"
          onFinish={handleAddPlayer}
          initialValues={initialValues}
        >
          <Form.List name="players">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row
                    key={field.key}
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    <Col className="gutter-row" span={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        key={field.key}
                        rules={[
                          {
                            required: true,
                            message: "Please input player name",
                          },
                        ]}
                      >
                        {/* <Select
                          showSearch
                          placeholder="Player name"
                          optionFilterProp="children"
                          onSelect={(value, option) =>
                            handlePlayerSelect(value, option)
                          }
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {players.map((player) => (
                            <Option key={field.key} value={player?.name}>
                              {player?.name}
                            </Option>
                          ))}
                        </Select> */}
                        <Select
                          showSearch
                          value={value}
                          placeholder="Select or add player"
                          onChange={handlePlayerSelect}
                          onSearch={handleSearch}
                          // onBlur={handleBlur}
                          notFoundContent={
                            <div
                              style={{ height: 0, minHeight: 0, padding: 0 }}
                            ></div>
                          }
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Button
                                type="link"
                                style={{
                                  display: "block",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                onClick={(e) => {
                                  if (
                                    searchText &&
                                    !players.find((p) => p.name === searchText)
                                  ) {
                                    handleAddNewPlayer(searchText);
                                    // setValue(searchText);
                                  } else {
                                    message.warning("Player already exists!");
                                  }
                                }}
                              >
                                Add new player
                              </Button>
                            </>
                          )}
                        >
                          {players.map((player) => (
                            <Option key={field.key} value={player.name}>
                              {player.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10}>
                      <Form.Item
                        {...field}
                        key={field.key}
                        name={[field.name, "buyInAmount"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input buy-in amount",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Buy-in amount"
                          type="number"
                          min={gameData?.minBuyIn}
                          inputMode="numeric"
                          pattern="[0-9]*"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={3}>
                      {form.getFieldsValue()?.players?.length > 1 && (
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => remove(field.name)}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <p style={{ color: "red", textAlign: "center" }}>
                  <small>{errorMessage}</small>
                </p>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({ name: "", buyInAmount: gameData?.minBuyIn })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add more players
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit">
                Add Players
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>
      <AddBuyIn
        open={addBuyInVisible}
        onClose={onAddBuyInClose}
        selectedPlayer={selectedPlayer}
        onAddBuyIn={handleAddBuyIn}
        minBuyIn={gameData?.minBuyIn}
      />
      <ChipOut
        open={chipOutVisible}
        onClose={onChipOutClose}
        selectedPlayer={selectedPlayer}
        onChipOut={handleChipOut}
      />
      <EditModal
        open={editModalVisible}
        onClose={onEditModalClose}
        selectedPlayer={selectedPlayer}
        onEditData={handleEditData}
        minBuyIn={gameData?.minBuyIn}
      />
      {gameData && (
        <Table
          rowKey={(record) => record?.playerId}
          style={{ fontSize: "16px !important" }}
          scroll={{
            x: true,
          }}
          dataSource={gameData?.players?.sort((a, b) =>
            a.name > b.name ? 1 : -1
          )}
          columns={columns}
        />
      )}
    </>
  ) : (
    <>
      <Flex justify="start" gap={10} align="center">
        <Button
          style={{ margin: "24px 0 12px" }}
          size="small"
          type="link"
          icon={<LeftOutlined />}
          onClick={() => setShowSettlementScreen(false)}
        />
        <Title level={3}>Settlement Details</Title>
      </Flex>
      <SettlementData
        settlementData={settlementData}
        setShowSettlementScreen={setShowSettlementScreen}
      />
    </>
  );
};

export default GameDetails;
