import { Button, Flex, Form, Input, Modal } from "antd";
import { useEffect, useRef } from "react";

const EditModal = ({ open, onClose, selectedPlayer, onEditData, minBuyIn }) => {
  const [form] = Form.useForm();
  const focusInputRef = useRef();

  useEffect(() => {
    if (open && focusInputRef.current) {
      focusInputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    form.setFieldsValue({
      buyInAmount: selectedPlayer?.buyInAmount,
      chipOutAmount: selectedPlayer?.chipOutAmount,
    });
  }, [selectedPlayer]);

  return (
    <Modal
      centered
      title={`Edit details for ${selectedPlayer?.name}`}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        name="dynamic_form"
        onFinish={(values) =>
          onEditData(
            selectedPlayer,
            Number(values?.buyInAmount),
            Number(values?.chipOutAmount),
            form
          )
        }
      >
        <Form.Item
          name="buyInAmount"
          label="Buy-in Amount"
          rules={[
            {
              required: true,
              message: "Please input the buy-in amount!",
            },
          ]}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            ref={focusInputRef}
            placeholder="Buy-in amount"
            type="number"
            min={minBuyIn}
          />
        </Form.Item>
        <Form.Item
          name="chipOutAmount"
          label="Chip-out Amount"
          rules={[
            {
              required: true,
              message: "Please input the chip-out amount!",
            },
          ]}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder="Chip-out amount"
            type="number"
          />
        </Form.Item>
        <Form.Item>
          <Flex justify="end">
            <Button type="primary" htmlType="submit">
              Update Details
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
