import { Button, Flex, Table } from "antd";
import PokerTransactions from "./Transaction";
import { useNavigate } from "react-router-dom";

const SettlementData = ({ settlementData, setShowSettlementScreen }) => {
  const navigate = useNavigate();
  const formatNumber = (value) => {
    return new Intl.NumberFormat("en-IN").format(value);
  };
  const columns = [
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Pay-out",
      dataIndex: "payOutAmount",
      key: "payOutAmount",
      render: (text) => text ? formatNumber(text): text,
    },
    {
      title: "Buy-in",
      dataIndex: "buyInAmount",
      key: "buyInAmount",
      render: (text) => text ? formatNumber(text) : text,
    },
  ];

  return (
    <>
      <Table
        dataSource={settlementData}
        columns={columns}
        rowKey={(record) => record?.playerId}
      />
      <PokerTransactions settlementData={settlementData} />
      <Flex gap={10} style={{ margin: "16px 0" }} justify="center" wrap="wrap">
        <Button type="primary" onClick={() => navigate("/create-game")}>
          Start New Game
        </Button>
        <Button type="primary" onClick={() => navigate("/leaderboard")}>
          View Leaderboard
        </Button>
      </Flex>
    </>
  );
};

export default SettlementData;
