import { Button, Flex, Form, Input, Modal } from "antd";
import { useEffect, useRef } from "react";

const ChipOut = ({ open, onClose, selectedPlayer, onChipOut }) => {
  const [form] = Form.useForm();
  const focusInputRef = useRef();

  useEffect(() => {
    if (open && focusInputRef.current) {
      focusInputRef.current.focus();
    }
  }, [open]);
  return (
    <Modal
      centered
      title={`Chip-out for ${selectedPlayer?.name}`}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        name="dynamic_form"
        onFinish={(values) =>
          onChipOut(selectedPlayer, Number(values?.chipOutAmount), form)
        }
      >
        <Form.Item
          name="chipOutAmount"
          label="Chip-out Amount"
          rules={[
            {
              required: true,
              message: "Please input the chip-out amount!",
            },
          ]}
        >
          <Input
            ref={focusInputRef}
            placeholder="Chip-out amount"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </Form.Item>
        <Form.Item>
          <Flex justify="end">
            <Button type="primary" htmlType="submit">
              Chip-out
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChipOut;
