import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { auth } from "../../firebase.config";

export function RequireAuth({ children }) {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false); // Update loading state
    });

    return () => unsubscribe(); // Clean up subscription
  }, []);

  // If still loading, return null or a loading spinner
  if (loading) {
    return null; // You can replace this with a loading spinner or message
  }

  // Redirect to login if not logged in, preserving the current location
  return isLoggedIn ? (
    children
  ) : (
    <Navigate
      to="/"
      state={{ from: location }} // Preserve the current location
      replace
    />
  );
}
