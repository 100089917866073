// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import NewGameForm from "./pages/NewGame/NewGameForm";
import { ConfigProvider } from "antd";
import GameDetails from "./pages/GameDetails/GameDetails";
import PageLayout from "./components/PageLayout/PageLayout";
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import { RequireAuth } from "./components/RequireAuth/RequireAuth";
import NotFoundPage from "./pages/PageNotFound/PageNotFound";
const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
        },
      }}
    >
      <Router>
        <PageLayout>
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route
              path="/create-game"
              exact
              element={
                <RequireAuth>
                  <NewGameForm />
                </RequireAuth>
              }
            />
            <Route path="/leaderboard" exact element={<Leaderboard />} />
            <Route
              path="/game-details/:gameId"
              exact
              element={
                <RequireAuth>
                  <GameDetails />
                </RequireAuth>
              }
            />
            <Route path="*" exact={true} element={<NotFoundPage />} />
          </Routes>
        </PageLayout>
      </Router>
    </ConfigProvider>
  );
};

export default App;
