import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Typography, message } from "antd"; // Import message from antd for displaying error messages
import { db } from "../../firebase.config";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const NewGameForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const focusInputRef = useRef();

  useEffect(() => {
    fetchPlayers();
    if (focusInputRef.current) {
      focusInputRef.current.focus();
    }
  }, []);

  const fetchPlayers = async () => {
    try {
      const response = await getDocs(collection(db, "players"));
      const playersData = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc?.id,
      }));
      setPlayers(playersData);
    } catch (error) {
      console.error("Error fetching players:", error);
      message.error("An error occurred while fetching players. Please try again later.");
    }
  };

  const handlePlayerSelect = async (value, option) => {
    const playerExists = players.find((player) => player.name === value);
    if (!playerExists) {
      try {
        const { id } = await addDoc(collection(db, "players"), {
          name: value,
          createdAt: new Date(),
          gamesPlayed: 0,
          winnings: 0,
          email: "",
        });
        setPlayers([...players, { name: value, id }]);
      } catch (error) {
        console.error("Error adding new player:", error);
        message.error("An error occurred while adding a new player. Please try again later.");
      }
    }
  };

  const handleStartGame = async (values) => {
    const { bigBlind, smallBlind, minBuyIn } = values;
    setLoading(true);
    try {
      const { id } = await addDoc(collection(db, "games"), {
        players: [],
        bigBlind: Number(bigBlind),
        smallBlind: Number(smallBlind),
        minBuyIn: Number(minBuyIn),
        createdAt: new Date(),
        startedAt: new Date(),
        status: "inProgress",
      });
      navigate(`/game-details/${id}`);
    } catch (error) {
      console.error("Error starting game:", error);
      message.error("An error occurred while starting the game. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title level={3}>Create New Game</Title>
      <Form form={form} onFinish={handleStartGame} layout="vertical">
        <Form.Item
          label="Minimum Buy-In"
          name="minBuyIn"
          rules={[
            { required: true, message: "Please enter the minimum buy-in" },
          ]}
        >
          <Input
            ref={focusInputRef}
            type="number"
            placeholder="Enter minimum buy-in"
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </Form.Item>
        <Form.Item
          label="Small Blind Amount"
          name="smallBlind"
          rules={[
            { required: true, message: "Please enter the small blind amount" },
          ]}
        >
          <Input
            type="number"
            placeholder="Enter small blind amount"
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </Form.Item>
        <Form.Item
          label="Big Blind Amount"
          name="bigBlind"
          rules={[
            { required: true, message: "Please enter the big blind amount" },
          ]}
        >
          <Input
            type="number"
            placeholder="Enter big blind amount"
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Start Game
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewGameForm;
