import { Button, Flex, Form, Input, Modal } from "antd";
import { useEffect, useRef } from "react";

const AddBuyIn = ({ open, onClose, selectedPlayer, onAddBuyIn, minBuyIn }) => {
  const [form] = Form.useForm();
  const focusInputRef = useRef();

  useEffect(() => {
    if (open && focusInputRef.current) {
      focusInputRef.current.focus();
    }
  }, [open]);
  const initialValue = {
    topUpAmount: minBuyIn,
  };

  return (
    <Modal
      centered
      title={`Add top-up for ${selectedPlayer?.name}`}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        name="dynamic_form"
        onFinish={(values) =>
          onAddBuyIn(selectedPlayer, Number(values?.topUpAmount), form)
        }
        initialValues={initialValue}
      >
        <Form.Item
          name="topUpAmount"
          label="Top-up Amount"
          rules={[
            {
              required: true,
              message: "Please input the top-up amount!",
            },
          ]}
        >
          <Input
            ref={focusInputRef}
            placeholder="Buy-in amount"
            inputMode="numeric"
            pattern="[0-9]*"
            type="number"
            min={minBuyIn}
          />
        </Form.Item>
        <Form.Item>
          <Flex justify="end">
            <Button type="primary" htmlType="submit">
              Add Top-up
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddBuyIn;
